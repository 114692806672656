import React, { useCallback, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useContentSearchQuery } from 'generated';
import styled from 'styled-components';
import clsx from 'clsx';
import { OemTheme } from 'assets/styles/themes/functions';
import { useLocation } from 'react-router-dom';
import { useLanguage } from 'components/util/Language';
import { debounce } from './debounce';
import { Analytics } from 'components/util/AnalyticEvents';

const ResultAnchor = styled.a`
  text-decoration: none !important;
  padding: 4px;
  background-color: white;
  border-right: 1px solid black;
  border-left: 1px solid black;

  &.last-result {
    border-bottom: 1px solid black;
  }

  :hover {
    background-color: ${OemTheme.colour.background.primary};
  }
`;

export function ContentSearchEngine() {
  const [undebouncedValue, setUndebouncedValue] = useState('');
  const [currentSearch, setCurrentSearch] = useState('');
  const location = useLocation();
  const lang = useLanguage();

  const showResults = currentSearch.length >= 2;
  const contentSearchQuery = useContentSearchQuery({
    skip: !showResults,
    variables: { language: lang, text: currentSearch, noLimit: false, },
  });

  // eslint-disable-next-line
  const debouncedSave = useCallback(
    debounce((nextValue: string) => setCurrentSearch(nextValue), 1000),
    []
  );

  Analytics.Interaction({
    field: 'Content Search Keyword',
    value: currentSearch,
  });

  const length = contentSearchQuery.data?.contentSearch.length ?? 0;

  // Only render the search on the standards page
  if (!/\/standards/gi.test(location.pathname)) return null;

  return (
    <Grid
      container
      item
      direction="row"
      wrap="wrap"
      alignContent="flex-start"
      style={{ width: '200px', marginTop: '18px' }}
    >
      <Grid item xs={12}>
        <small style={{ fontSize: '12px' }}>
          {{ EN: 'Search in Standards', FR: 'Rechercher des normes' }[lang]}
        </small>
      </Grid>
      <Grid item xs={12}>
        <input
          type="text"
          placeholder=""
          value={undebouncedValue}
          onChange={(event) => {
            setUndebouncedValue(event.target.value);
            debouncedSave(event.target.value);
          }}
          style={{ width: '100%', height: '32px', fontSize: '14px', padding: '4px' }}
        />
      </Grid>
      <Grid container item xs={12} direction="column">
        {showResults &&
          (length > 0 || contentSearchQuery.loading ? (
            contentSearchQuery.data?.contentSearch.map((con, idx) => (
              <ResultAnchor
                className={clsx(idx === length - 1 && 'last-result')}
                href={con.path}
                onClick={(e) => {
                  e.preventDefault();
                  Analytics.Hookless.SearchRedirect(currentSearch, con.path);
                  window.location.href = con.path;
                }}
                key={con._id}
              >
                {con.title}
              </ResultAnchor>
            ))
          ) : (
            <small style={{ color: 'red', marginTop: '4px' }}>
              {{ EN: 'No Results found.', FR: 'Aucun résultat trouvé.' }[lang]}
            </small>
          ))}
      </Grid>
    </Grid>
  );
}
