import { useCallback, useEffect, useState, useTransition } from 'react';
import { debounce } from 'components/fields/selectors/templates/SearchDropdown/debounce';
import { useLocalStorage } from 'components/util/LocalStorage';
import { LanguageLabel } from 'suites/sterling/app/types';
import { useSearchContentModel } from './model.SearchContent';
import { useSearchHistoryModel } from './model.SearchHistory';
import { useSearchSuggestionsModel } from './model.SearchPages';

interface SearchHistoryItem {
  searchDate: string;
  rank: number;
  keys: string[];
  result: {
    label: string;
    // value acts as unique identifier for search history items
    value: string;
  };
}

export interface SearchMenuSection {
  optionType: LanguageLabel;
  items: {
    label: string;
    value: string;
    ancestors?: { __typename?: string; title: string; path: string }[];
    content?: string;
  }[];
}

export function useSearchHistory(initialValue?: string[]) {
  const [localSearchHistory, setLocalSearchHistory, clearSearchHistory] = useLocalStorage(
    'sterling_search_history',
    initialValue
  );

  // Filters out search history items that are older than 6 months and
  // sets the search history with the result
  const cleanOldSearchHistory = (newSearchValue?: string) => {
    const currentDate = new Date();
    const sixMonthsAgo = new Date(currentDate.setMonth(currentDate.getMonth() - 6));

    // Items that don't match the new search key and are older than 6 months
    const filteredSearchHistory = localSearchHistory.filter(
      (item: SearchHistoryItem) =>
        item.result.value !== newSearchValue || new Date(item.searchDate) > sixMonthsAgo
    );
    setLocalSearchHistory(filteredSearchHistory);
  };

  // Cleans search history and adds a search history item to the local storage
  const addSearchHistory = (
    searchKey: string,
    searchHistoryItem: Pick<SearchHistoryItem, 'result'>
  ) => {
    cleanOldSearchHistory(searchHistoryItem.result.value);
    const searchDate = new Date().toISOString();
    const existingSearchHistoryItem = localSearchHistory.find(
      (item: SearchHistoryItem) => item.result.value === searchHistoryItem.result.value
    );

    // If the search history item already exists, increment the rank
    if (existingSearchHistoryItem) {
      setLocalSearchHistory([
        {
          ...existingSearchHistoryItem,
          rank: existingSearchHistoryItem.rank + 1,
          keys: Array.from(new Set([searchKey, ...(existingSearchHistoryItem?.keys ?? [])])),
          searchDate,
        },
        ...localSearchHistory.filter(
          (item: SearchHistoryItem) => item.result.value !== searchHistoryItem.result.value
        ),
      ]);
      return;
    }

    // add current date to searchHistoryItem and updates search history
    setLocalSearchHistory([
      {
        ...{
          ...searchHistoryItem,
          rank: 1,
          keys: [searchKey],
          searchDate,
        },
        searchDate: new Date().toISOString(),
      },
      ...localSearchHistory,
    ]);
  };

  return {
    searchHistory: localSearchHistory as SearchHistoryItem[],
    addSearchHistory,
    clearSearchHistory,
  };
}

export function useSearch(searchTerm: string, noLimit?: boolean) {
  const searchHistoryHook = useSearchHistory([]);
  const [isPending, startTransition] = useTransition();
  const [currentSearchTerm, setCurrentSearch] = useState('');

  const isSearchValid = currentSearchTerm.length >= 2;

  const { contentResultsState, contentSearchPending, resetSearchContentResultsState } =
    useSearchContentModel({
      searchTerm: currentSearchTerm,
      isSearchValid,
      noLimit,
    });

  const { searchHistoryState } = useSearchHistoryModel({ searchTerm: currentSearchTerm });

  const {suggestionResultsState, resetSuggestionResultsState, suggestionSearchPending} = useSearchSuggestionsModel({searchTerm: currentSearchTerm, isSearchValid})
  const handleSearchResultClick = (
    searchKey: string,
    result: { label: string; value: string }
  ) => {
    searchHistoryHook.addSearchHistory(searchKey, { result });
    window.location.assign(result.value)
  };

  // eslint-disable-next-line
  const debouncedSave = useCallback(
    debounce((nextValue: string) => {
      setCurrentSearch(nextValue);
    }, 500),
    []
  );

  const onClearClick = () => {
    setCurrentSearch('');
    resetSearchContentResultsState();
    resetSuggestionResultsState();
  };

  useEffect(() => {
    if (searchTerm !== currentSearchTerm) {
      startTransition(() => {
        debouncedSave(searchTerm);
      });
    }
  }, [searchTerm]);

  return {
    isSearchValid,
    isPending,
    handleSearchResultClick,
    searchPending: contentSearchPending || suggestionSearchPending,
    suggestionResultsState,
    contentResultsState,
    searchHistoryState,
    onClearClick,
  };
}
