import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { OemTheme } from 'assets/styles/themes/functions';
import { DropdownBaseStyle } from './styled.Dropdown.Base';
import { DropdownSelectorProps } from './DropdownSelector';

// eslint-disable-next-line
export const useDropdownSelectorStyles = ({ small, buttonLook }: DropdownSelectorProps) => {
  const dropdownBaseStyle = DropdownBaseStyle(small, buttonLook);
  return makeStyles((theme: Theme) =>
    createStyles({
      ...dropdownBaseStyle,
      input2: {
        border: `solid 3px ${OemTheme.colour.text.default} !important`,
        textAlign: 'left',
        width: '100%',
        '& svg': {
          position: 'absolute',
          right: '9px',
          top: '50%',
          transform: 'translateY(-50%)',
        },
        '& label' : {
          top: '-15px',
          left: '-15px',
          maxWidth: 'unset'
        },
      },
      oemLabel: {
        textAlign: 'left',
        color: OemTheme.colour.text.default,
        borderColor: OemTheme.colour.text.default,
        whiteSpace: 'nowrap',
        zIndex: '0 !important',
        top: -15,
        left: -15,
        '&.Mui-focused': {
          color: OemTheme.colour.text.default,
        },
      },
      unfocused: {
        color: OemTheme.colour.text.default,
        zIndex: '0 !important',
        '&.Mui-focused': {
          color: OemTheme.colour.text.default,
          // backgroundColor: OemTheme.colour.indicator.inactive,
          padding: '0 4px',
          marginTop: '-11px !important',
        },
        top: '-5px !important',
        left: '0 !important',
      },
      formControl: {
        margin: theme.spacing(1),
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
    })
  )();
};

export const useMenuItemStyles = () => {
  return makeStyles(() =>
    createStyles({
      dropDownItems: {
        fontSize: '16px',
        fontFamily: `${OemTheme.font.regular}`,
      },
    })
  )();
};
