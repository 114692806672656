import { gql } from '@apollo/client';
import { LanguageCode, useUpdatePreferredLanguageMutation } from 'generated';
import { useLanguageController } from 'components';
import { useLanguage } from 'components/util/Language';
import { useAuthorization } from 'suites/sterling/app/context/authorization';
import Button from '@mui/material/Button/Button';
import { SxProps, Theme } from '@mui/material/styles';

// eslint-disable-next-line
gql`
  mutation updatePreferredLanguage($language: LanguageCode!) {
    userUpdateLanguage(language: $language)
  }
`;

export function LanguageToggle({buttonStyleOverride}: {buttonStyleOverride?: SxProps<Theme>}) {
  const { authorized, refreshAuth } = useAuthorization();
  const lang = useLanguage();
  const { setURLLanguage } = useLanguageController();
  const [updatePreferredLanguage] = useUpdatePreferredLanguageMutation({onCompleted: () => {
    refreshAuth();
  }});

  const alternateLang = {EN: 'FR', FR: 'EN'}[lang] as LanguageCode;
  const alternateLangFull = {EN: 'Français', FR: 'English'}[lang];

  return (
    <Button
      size='medium'
      variant='text'
      id="test-language-toggle"
      sx={buttonStyleOverride ?? {}}
      onClick={() => {
        if (authorized) {
          updatePreferredLanguage({
            variables: { language: alternateLang },
          });
        }
        setURLLanguage(alternateLang);
      }}
    >
      {alternateLangFull}
    </Button>
  );
}
